
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














































































































.button-send,
[class*='button-send--'] {
  @extend %button-nostyle;

  position: relative;
  box-sizing: content-box;
  padding: 1.5rem 3.45rem 1.4rem;
  color: $c-white;
  line-height: 2.8rem;
  letter-spacing: 0.5px;
  background: $c-blue-80;
  border: 0;
  border-radius: 6rem;
  transition: 0.3s $ease-out-cubic;
}

.button__send__label {
  $label-offset: 1em;

  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  transition: 0.3s $ease-out-cubic;

  .icon {
    margin-right: $spacing * 0.5;
    color: $c-blue-40;
  }

  &::before {
    @include center-x;

    content: attr(data-label);
    z-index: 1;
    width: 100%;
    margin-top: $label-offset * 2;
    color: $c-white;
    opacity: 0;
    transition: 0.2s $ease-out-cubic;
  }

  button:not([disabled]):hover > &,
  button:not([disabled]):focus-visible > & {
    color: transparent;
    transform: translate3d(0, -$label-offset, 0);
    transition: 0.3s $ease-out-cubic;

    &::before {
      opacity: 1;
      transition: 0.3s $ease-in-cubic;
    }
  }
}

.button-send__animation {
  display: inline-block;
}

.button-send__animation__dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  white-space: nowrap;
  background: $c-white;
  border-radius: 50%;
  animation: sending 1s infinite;

  & + & {
    margin-left: $spacing * 0.25;
    animation-delay: 0.1s;
  }

  &:last-child {
    animation-delay: 0.2s;
  }
}

.label-enter-active,
.label-enter-to {
  opacity: 0;
}

@keyframes sending {
  25% {
    transform: translateY(-5px);
  }

  50% {
    transform: translateY(0);
  }
}
