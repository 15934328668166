
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















































































$icon-w: 3.5rem;

.form-group {
  flex-basis: 100%;
  cursor: pointer;
}

.form-checkbox {
  @extend %visually-hidden;
}

.form-label {
  position: relative;
  display: block;
  padding-left: $icon-w + $spacing;
  color: $c-black-80;
  cursor: pointer;
}

.form-label__icon {
  position: absolute;
  top: -5px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $icon-w;
  height: $icon-w;
  background: $c-black-10;
  border: 3px solid $c-black-10;
  border-radius: 50%;
  transition: background 0.2s, border-color 0.2s;

  .icon {
    width: 1.6rem;
    height: 1.6rem;
    color: $c-blue-80;
    opacity: 0;
    transition: opacity 0.2s;
  }

  .is-invalid & {
    border-color: $c-red;
  }

  .form-label:hover &,
  .form-checkbox:focus-visible + .form-label & {
    background: $c-blue-20;
    border-color: $c-blue-20;
  }

  .form-checkbox:checked + .form-label & {
    background: $c-blue-40;
    border-color: $c-blue-40;

    .icon {
      opacity: 1;
    }
  }
}
