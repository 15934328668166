
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







































































































































































































.single,
[class*='single--'] {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $c-blue;

  @include mq($until: m) {
    padding: 0;
  }
}

.single__outside-link {
  @include get-all-space;

  position: fixed;
}

.single-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // width: auto;
  width: 100%;
}

.single__main {
  position: relative;
  z-index: 1;
  flex: 1;
  background: $c-white;

  @include mq(m) {
    margin: 0 col(1, 20);
    border-radius: 6rem;
  }

  @include mq(xxxl) {
    margin: 0;
  }
}

.single__close {
  @extend %button-nostyle;

  display: block;
  width: fit-content;
  margin-right: col(1, 18);
  margin-left: auto;
}

.single__header,
.agenda-details,
.single__infos,
.single__picture {
  width: auto;
  margin-right: col(1, 20);
  margin-left: col(1, 20);

  @include mq(m) {
    margin-right: col(1, 18);
    margin-left: col(1, 18);
  }

  @include mq(l) {
    margin-right: col(2, 18);
    margin-left: col(2, 18);
  }

  @include mq(xxxl) {
    margin-right: col(3, 20);
    margin-left: col(3, 20);
  }
}

.single__header__title {
  margin: $spacing 0;

  .single--page & {
    @extend %text-center;
  }

  @include mq($until: m) {
    font-size: 4rem;

    &.shrinked {
      font-size: 3.2rem; // h3 mobile
    }
  }

  @include mq(l) {
    font-size: 7.6rem;

    &.shrinked {
      font-size: 6.8rem;
    }
  }
}

.single__infos {
  margin-bottom: $spacing * 2;

  @include mq(m) {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: $spacing * 3;
  }
}

.single__infos__description {
  padding-right: col(1);
  // font-size: 1.75em;
  // line-height: 1.4;
}

.single__infos__cta {
  flex-shrink: 0;
  margin-top: $spacing * 1.2;

  @include mq(m) {
    margin-top: 0;
  }
}

.single__content {
  margin: $spacing * 2 0;
  padding: 0 col(2, 20);

  @include mq(l) {
    margin: $spacing * 2 0;
    padding: 0 col(4, 18);
  }

  @include mq(xxxl) {
    padding: 0 col(5, 20);
  }
}

.single__related {
  padding-right: col(1, 20);
  padding-left: col(1, 20);
  border-radius: 0 0 6rem 6rem;

  @include mq(m) {
    padding-right: col(1, 18);
    padding-left: col(1, 18);
  }

  @include mq(xxxl) {
    padding-right: col(1, 20);
    padding-left: col(1, 20);
  }
}
