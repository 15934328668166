
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































































































.agenda-details,
[class*='agenda-details--'] {
  margin-bottom: $spacing * 2;

  @include mq(m) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $spacing * 0.75;
    margin-bottom: $spacing * 3;
    padding: $spacing col(0.5, 14);
    background: $c-white;
    border-radius: 1rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  }
}

.agenda-details__date,
.agenda-details__location {
  height: 6rem;
  margin: 0 0 $spacing * 0.7;
  padding: 0 col(1, 16);
  background: $c-white;
  border-radius: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);

  @include mq(m) {
    height: auto;
    margin-bottom: 0;
    padding: 0;
    box-shadow: none;
  }
}

.agenda-details__action {
  margin-top: $spacing * 2;

  @include mq(m) {
    margin-top: 0;
  }
}

.agenda-details__action__full {
  @extend %fw-normal;

  display: inline-block;
  padding: $spacing * 0.75 $spacing * 1.5 $spacing * 0.65;
  color: $c-black-80;
  line-height: 2.8rem;
  background: $c-black-40;
  border-radius: 5rem;
}

.agenda-details__date {
  display: flex;
  align-items: center;

  ::v-deep span {
    margin-right: 0.5em;
  }
}

.agenda-details__date__schedule {
  position: relative;
}

.agenda-details__date__schedule__content {
  @extend %list-nostyle;

  position: absolute;
  z-index: 1;
  bottom: -$spacing * 0.5;
  left: 50%;
  padding: 1.2rem 5.8rem;
  color: $c-black-80;
  background: $c-white;
  border-radius: 1rem;
  transform: translate(-50%, 100%);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);

  li {
    @extend %fw-medium;

    display: flex;
    justify-content: space-between;
    padding: $spacing 0;
    white-space: nowrap;
  }

  li + li {
    border-top: 2px solid $c-black-10;
  }

  .day {
    margin-right: 10rem;
    color: #2a2a2a;
  }

  @include mq(m) {
    bottom: -$spacing * 2;
  }
}

.agenda-details__location {
  @include mq(m) {
    flex: col(5, 12) 0 0;
  }
}

.fade-in-enter-active {
  transition: all 0.3s ease-out;
}

.fade-in-leave-active {
  transition: all 0.2s ease-in;
}

.fade-in-enter,
.fade-in-leave-to {
  transform: translate(-50%, 110%);
  opacity: 0;
}
